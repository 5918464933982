import React from "react";
import Header from "../../../../components/header/Header";
// import Content from "../components/main/content/Content";
import Content from "../../../../components/main/content/DynamicContent";
import Sidebar from "../../../../components/main/sidebar/Sidebar";
import Footer from "../../../../components/footer/Footer";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  let featured_post = data?.cms?.recent_story.filter(checkFetured);
  function checkFetured(element) {
    return element.is_feature_post;
  }

  return (
    <>
      <div className="layout">
        <Helmet>
          <meta name="d103bf43fb93b7f" content="c0b9cd4d499846b447bed15f2e787549" />
        </Helmet>
        <Header domain={data?.cms?.domain} category={data?.cms?.categories} allPosts={allPosts} engine={options} story={data?.cms?.story} />
        <div className="container is-max-widescreen">
          <div className="columns m-0">
            <Content domain={data?.cms?.domain} />
            <Sidebar recent_story={featured_post} />
          </div>
        </div>
        <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={"editor@thenerdseries.com"} />
      </div>
    </>
  );
};
export default ArticlePage;

export const query = graphql`
  query article($slug: ID!) {
    cms {
      story: CMS_Story(id: $slug) {
        id
        title
        slug
        image
        meta_description
        meta_keywords
        meta_title
        pub_date
      }
      domain: CMS_Domain(id: "1") {
        id
        logo
        logo_light
        domain_url
        name
        description
        favicon
        header_script
      }
      categories: CMS_CategoryByDomain(domain_id: "1") {
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "1") {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
