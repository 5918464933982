import React from "react";
import "./style.scss";
import { Link } from "gatsby";
import ClientSearch from "../ClientSearch";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";

const Header = ({ category, allPosts, engine, domain, story, banner }) => {
  return (
    <>
      {/* <pre style={{ textAlign: "left" }}>{JSON.stringify({ story }, null, 2)}</pre> */}
      <header className="header-two">
        <Helmet>
          {ReactHtmlParser(domain?.header_script)}
          {story ? (
            <title>{`${story?.meta_title ? story?.meta_title : story?.title} | ${
              domain?.name
            }`}</title>
          ) : (
            <title>{`${domain?.name}`}</title>
          )}
          <meta name="description" content={story?.meta_description} />
          <meta name="keywords" content={story?.meta_keywords} />
        </Helmet>
        <div className="container is-max-widescreen">
          <nav className="navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">
              <Link to={`/`} className="navbar-item">
                <img src={domain?.logo} alt={domain?.name} />
              </Link>
              <a
                role="button"
                className="navbar-burger"
                aria-label="menu"
                aria-expanded="false"
                data-target="navbarBasicExample"
              >
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
                <span aria-hidden="true"></span>
              </a>
            </div>

            <div className="navbar-menu">
              <div className="navbar-end">
                <Link to={`/`} className="navbar-item">
                  Home
                </Link>
                {category?.map((categoryData) => {
                  return (
                    <Link to={`/${categoryData.slug}`} className="navbar-item">
                      {categoryData?.name}
                    </Link>
                  );
                })}
                <ClientSearch allPosts={allPosts} engine={engine} />
              </div>
            </div>
          </nav>
        </div>
      </header>
    </>
  );
};
export default Header;
